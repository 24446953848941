<template>
  <a-spin :spinning="loading">
    <div class="bg">
      <div class="wrapper page-wrap" style="">
        <div class="detail">
          <div class="avater">
            <img src="~@/assets/avatar.jpg" />
          </div>
          <div class="name">
            <span class="mr-20">{{ exam_detail.record_name }}</span>
            <span>{{ exam_detail.record_phone }}</span>
          </div>
          <div class="score">
            <span class="big">{{ exam_detail.record_score }}</span>
            <span>分</span>
          </div>
          <div class="Sj"></div>
          <div class="exam-name">{{ exam_detail.exam_name }}</div>

          <div class="msg-box">
            <div class="box-e flex flex-between">
              考试用时
              <span style="color: #212531; opacity: 0.7">{{
                getTime(exam_detail.record_duration)
              }}</span>
            </div>
            <div class="box-e flex flex-between">
              提交时间
              <span style="color: #212531; opacity: 0.7">{{
                exam_detail.record_finish_time
              }}</span>
            </div>
            <div class="box-e flex flex-between">
              考试结果
              <span
                v-if="
                  exam_detail.record_status == 1 &&
                  exam_detail.record_score_status == 1
                "
                :style="{ color: '#1276cb' }"
                >已通过</span
              >
              <span v-else :style="{ color: '#d63030' }">未通过</span>
            </div>
          </div>
          <div class="back-btn" @click="goback">返回</div>
        </div>
      </div>
      <div class="bg-box"></div>
    </div>
  </a-spin>
</template>
<script setup>
import { getExamRecordData } from "@/api/main.js";
import { useStore } from "vuex";
import { ref, reactive, onUnmounted, onMounted } from "vue";
import url from "@/utils/url.js";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";
import { message, Modal, notification } from "ant-design-vue";
const route = useRoute();
const visible = ref(false);
let query = decode(route.query?.t).split(",");
const store = useStore();
const loading = ref(true);
const exam_detail = ref({});
console.log(query);
getExamRecordData({ user_id: store.state.user.user_id, record_id: query[0] })
  .then(res => {
    if (res.code == 200) {
      console.log(res);
      exam_detail.value = res.data.exam_record;
    } else {
      message.error(res.msg);
    }
    loading.value = false;
  })
  .catch(err => {});
const goback = () => {
  url.replaceTo("/exam/index");
};
const getTime = v => {
  if (v >= 3600) {
    let h = Math.floor(v / 3600);
    let m = Math.floor((v - h * 3600) / 60);
    let s = v - h * 3600 - m * 60;
    let mm = m == 0 ? "" : `${m}分`;
    return `${h}小时${mm}${s}秒`;
  } else if (v < 3600 && v >= 60) {
    let m = Math.floor(v / 60);
    let s = v - m * 60;
    return `${m}分${s}秒`;
  } else {
    return v + "秒";
  }
};
</script>
<style lang="less" scoped>
.bg {
  width: 100%;
  background-image: linear-gradient(0deg, #f3f5f8 0%, #1276cb 100%);
  padding-bottom:50px;
}
.detail {
  width: 900px;
  height: 636px;
  background-color: #ffffff;
  border-radius: 20px;
  margin: 117px auto 0 auto;
  position: relative;
  z-index: 5;
  .avater {
    width: 140px;
    height: 140px;
    background-color: #1276cb;
    border: solid 4px #ffffff;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    position: absolute;
    left: 50%;
    top: -70px;
    transform: translateX(-50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .name {
    position: absolute;
    top: 93px;
    left: 50%;
    transform: translateX(-50%);
    color: #212531;
    font-size: 18px;
  }
  .score {
    position: absolute;
    top: 141px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-self: flex-start;
    span {
      font-size: 20px;
      color: #212531;
    }
    .big {
      font-size: 60px;
      line-height: 1;
      font-weight: bold;
      color: #1276cb;
    }
  }
  .Sj {
    width: 0;
    height: 0;
    border-right: 22px solid transparent;
    border-left: 22px solid transparent;
    border-bottom: 22px solid #f3f5f8;
    border-top: 22px solid transparent;
    position: absolute;
    top: 199px;
    left: 50%;
    transform: translateX(-50%);
  }
  .exam-name {
    position: absolute;
    top: 239px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    line-height: 1;
    color: #212531;
    padding: 15px 70px;
    text-align: center;
    background-color: #f3f5f8;
    white-space: nowrap;
    border-radius: 10px;
    font-weight: 500;
  }
  .msg-box {
    position: absolute;
    top: 298px;
    width: 761px;
    left: 50%;
    font-size: 18px;
    transform: translateX(-50%);
    .box-e {
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #dfdfdf;
    }
  }
  .back-btn {
    width: 400px;
    height: 60px;
    background-color: #1276cb;
    border-radius: 30px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 60px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 46px;
    cursor: pointer;
  }
}
</style>
